//@author travis
import { DataAccess } from "../dataaccess/data.access";
import { Cache, CacheRepository, Invalidate } from "../decorators/cache.decorator";
import { S25Util } from "../util/s25-util";
import { S25Const } from "../util/s25-const";
import { jSith } from "../util/jquery-replacement";
import { ListGeneratorService } from "./list.generator.service";
import { Timeout } from "../decorators/timeout.decorator";
import { SearchCriteriaService } from "./search/search-criteria/search.criteria.service";
import { UserprefService } from "./userpref.service";
import { SpaceSatisfactionI } from "../pojo/SpaceSatisfactionI";
import { Eval25Service } from "./eval25.service";
import { Location } from "../pojo/Location";
import { Proto } from "../pojo/Proto";
import { AvailWSResponse } from "./resource.space.avail.service";
import NumericalBoolean = Proto.NumericalBoolean;
import ISODateString = Proto.ISODateString;
import WSSpacesAvail = AvailWSResponse.WSSpacesAvail;
import { S25WsNode } from "../pojo/S25WsNode";
import { S25ItemI } from "../pojo/S25ItemI";
import { CopyPropertiesI } from "./copy.object.service";
import { CopyObjectUtil } from "../modules/s25-more-actions/copy.object.util";
import { MicroUtil } from "./MicroServices/micro.util";
import { S25WsMicroService } from "./MicroServices/S25WsMicroService";
import Layout = Location.Layout;
import Layout2 = Location.Layout2;
import OffsetISODateString = Proto.OffsetISODateString;
import { EventPerm } from "./avail.service";
import { AccessLevel } from "../pojo/Fls";
import CharBoolean = Proto.CharBoolean;

const SPACE_ARRAYS = { space: true, layout: true, categories: true, contact: true, hours: true };

type Space = {
    id: number;
    spaceName?: string;
    spaceFormalName?: string;
    maxCapacity?: number;
    fillRatio?: number;
    partitionId?: number;
    buildingId?: number;
    alwaysShared?: boolean;
    comments?: string;
    instructions?: string;
    categories?: [{ categoryId: number }];
    attributes?: [{ attributeId: number; value?: string }];
    features?: [{ featureId: number; quantity?: number }];
    roles?: [{ roleId: number; contactId: number }];
};

export interface S25WsSpace extends S25WsNode {
    building_id?: number;
    building_name?: string;
    edit_perm?: AccessLevel;
    instructions?: string;
    comments?: string;
    assign_perm?: AccessLevel;
    schedule_perm?: AccessLevel;
    max_capacity?: number;
    fill_ratio?: number;
    comment_id?: string;
    partition_id?: number;
    space_name?: string;
    always_shared?: CharBoolean;
    space_id?: number;
    formal_name?: string;
    favorite?: CharBoolean;
    instruction_id?: number;
    partition_name?: string;
    direct_schedule?: {
        direct_schedule_duration: number;
        direct_schedule_type_id: number;
        direct_schedule_type_name: string;
    };
}

// unused (for now) type SpaceMicroUpdatePayload = Partial<Space> & { id: number };

export class SpaceService {
    @Invalidate({ serviceName: "SpaceService" })
    public static putSpace(itemId: number, payload: any) {
        return SpaceService.getSpaceIncludes(itemId, []).then(function (item) {
            S25Util.coalesceDeep(payload, item);
            delete payload.crc;
            return DataAccess.put(
                DataAccess.injectCaller("/space.json?space_id=" + itemId, "SpaceService.putSpace"),
                S25Util.getPayload("spaces", "space", "space_id", "mod", itemId, payload),
            );
        });
    }

    @Invalidate({ serviceName: "SpaceService" })
    public static putSpaceReplace(itemId: number, payload: any) {
        return SpaceService.getSpaceIncludes(itemId, []).then(function (item) {
            Object.assign(item, payload);
            delete payload.crc;
            return DataAccess.put(
                DataAccess.injectCaller("/space.json?space_id=" + itemId, "SpaceService.putSpace"),
                S25Util.getPayload("spaces", "space", "space_id", "mod", itemId, payload),
            );
        });
    }

    @Timeout
    public static _getBlackouts(searchQuery?: string) {
        return DataAccess.get<{ blackout?: Blackout[] }>(
            DataAccess.injectCaller("/blackouts/list.json" + (searchQuery || ""), "SpaceService.getBlackouts"),
        );
    }

    @Cache({ immutable: true, targetName: "SpaceService" })
    public static getBlackouts(searchQuery?: string, forceRefresh?: boolean) {
        forceRefresh && CacheRepository.invalidateByService("SpaceService", "getBlackouts");
        return SpaceService._getBlackouts(searchQuery).then((blackouts) => {
            for (let blackout of blackouts.blackout || []) {
                blackout.init_start_dt = S25Util.date.dropTZString(blackout.init_start_dt);
                blackout.init_end_dt = S25Util.date.dropTZString(blackout.init_end_dt);
            }
            return blackouts;
        });
    }

    @Timeout
    @Invalidate({ serviceName: "SpaceService", methodName: "getBlackouts" })
    public static putSpacesBlackout(
        profileName: string,
        profileCode: string,
        initStartDt: any,
        initEndDt: any,
        addedSpaceIds: number[],
        removedSpaceIds: number[],
    ) {
        addedSpaceIds = addedSpaceIds || [];
        removedSpaceIds = removedSpaceIds || [];

        return DataAccess.put(
            DataAccess.injectCaller(
                "/blackouts/spaces.json?itemName=" +
                    encodeURIComponent(profileName) +
                    "&profile_code=" +
                    encodeURIComponent(profileCode || "") +
                    "&start_dt=" +
                    S25Util.date.toS25ISODateTimeStr(initStartDt) +
                    "&end_dt=" +
                    S25Util.date.toS25ISODateTimeStr(initEndDt),
                "SpaceService.putSpacesBlackout",
            ),
            {
                root: {
                    added: addedSpaceIds.map(function (id) {
                        return { room_id: id };
                    }),
                    removed: removedSpaceIds.map(function (id) {
                        return { room_id: id };
                    }),
                },
            },
        );
    }

    @Timeout
    @Invalidate({ serviceName: "SpaceService", methodName: "getBlackouts" })
    public static deleteBlackout(profileName: string, profileCode: string, initStartDt: any, initEndDt: any) {
        return DataAccess.delete(
            DataAccess.injectCaller(
                "/blackouts/delete.json?itemName=" +
                    encodeURIComponent(profileName) +
                    "&profile_code=" +
                    encodeURIComponent(profileCode || "") +
                    "&start_dt=" +
                    S25Util.date.toS25ISODateTimeStr(initStartDt) +
                    "&end_dt=" +
                    S25Util.date.toS25ISODateTimeStr(initEndDt),
                "SpaceService.deleteBlackout",
            ),
        );
    }

    @Timeout
    public static putBlackout(
        profileName: string,
        profileCode: string,
        initStartDt: string,
        initEndDt: string,
        newProfileName: string,
        newInitStartDt: string,
        newInitEndDt: string,
        newProfileCode: string,
        newRecType: number,
        newSpaceIds: number[],
        newDates: any,
        newComment: string = "",
    ) {
        newSpaceIds = newSpaceIds || [];
        newDates = newDates || [];

        return DataAccess.put(
            DataAccess.injectCaller(
                "/blackouts/edit.json?itemName=" +
                    encodeURIComponent(profileName) +
                    "&profile_code=" +
                    encodeURIComponent(profileCode || "") +
                    "&start_dt=" +
                    (S25Util.date.toS25ISODateTimeStr(initStartDt) || "") +
                    "&end_dt=" +
                    (S25Util.date.toS25ISODateTimeStr(initEndDt) || ""),
                "SpaceService.putBlackout",
            ),
            {
                root: {
                    profile_name: newProfileName,
                    profile_code: newProfileCode,
                    rec_type: newRecType,
                    init_start_dt: S25Util.date.toS25ISODateTimeStr(newInitStartDt),
                    init_end_dt: S25Util.date.toS25ISODateTimeStr(newInitEndDt),
                    rooms: newSpaceIds.map(function (id) {
                        return { room_id: id };
                    }),
                    blackout: newDates.map(function (date: any) {
                        return { start_dt: date.start_dt, end_dt: date.end_dt };
                    }),
                    comment: newComment || "",
                },
            },
        );
    }

    @Timeout
    public static getSpacesOptions(itemName: string) {
        return DataAccess.get(
            DataAccess.injectCaller(
                "/spaces.json?name=" + itemName + "&can_schedule=T&sort=space_name&projection_accl=space-options",
                "SpaceService.getSpacesOptions",
            ),
        ).then(function (data) {
            if (!data || !data.spaces || !data.spaces.space) {
                return null;
            }
            if (S25Util.array.isArray(data.spaces.space)) {
                return data.spaces.space.reduce(function (result: any, space: any) {
                    result.push({
                        itemId: space.space_id,
                        itemName: space.space_name,
                        itemDesc: space.formal_name,
                    });
                    return result;
                }, []);
            } else {
                let space = data.spaces.space;
                return [
                    {
                        itemId: space.space_id,
                        itemName: space.space_name,
                        itemDesc: space.formal_name,
                    },
                ];
            }
        });
    }

    @Timeout
    public static getSpacesAvailability(queryId: number, capacity: any, dates: string[]): Promise<any> {
        var searchQuery =
            "&query_id=" +
            (queryId || "") +
            "&min_capacity=" +
            capacity.min +
            "&max_capacity=" +
            capacity.max +
            "&can_schedule=T&sort=formal_name";
        return SpaceService.getSpacesBySearchQuery(searchQuery, null, "list").then(function (data) {
            var ids = S25Util.propertyGetAll(data, "id");
            return SpaceService.getSpaceDatesAvailability(ids, dates).then(function (spaceAvail) {
                var ret: any[] = [];
                var spaces = spaceAvail && spaceAvail.spaces && spaceAvail.spaces.space;

                jSith.forEach(spaces, function (_, space) {
                    if (space && space.has_conflicts !== "T") {
                        ret.push({
                            itemId: parseInt(space.space_id),
                            itemTypeId: 4,
                            itemName: space.space_name,
                            itemDesc: space.formal_name,
                            maxCapacity: parseInt(space.max_capacity),
                        });
                    }
                });

                //sort by max capacity, itemName both asc -- pad max capacity in concat sort so it sorts correctly lexicographically
                ret.sort(
                    S25Util.shallowSortMultProps(["maxCapacity", "itemName"], null, null, null, function (val: any) {
                        if (typeof val === "number") {
                            return S25Util.leftPad(val, 4, "0");
                        }
                        return val;
                    }),
                );

                return ret;
            });
        });
    }

    @Timeout
    public static deleteLocation(id: number) {
        return DataAccess.delete(
            DataAccess.injectCaller("/space.json?space_id=" + id, "SpaceService.deleteLocation"),
        ).then(
            function (resp) {
                resp = S25Util.prettifyJson(resp);
                var isSuccess =
                    (resp && resp.results && resp.results.info && resp.results.info.msg_id) === "RM_I_DELETED";
                return { error: !isSuccess, success: isSuccess };
            },
            function (error) {
                console.error(error);
                return { error: error, success: false };
            },
        );
    }

    //Keeping this DAO seperate for easier mocking
    public static getSpaceDatesAvailabilityDao(
        spaceIds: number[],
        datesArr: any,
        contextEventId?: number,
        contextProfileId?: number,
    ) {
        spaceIds = S25Util.array.forceArray(spaceIds);
        var body = {
            space_availability: {
                event: {
                    event_id: contextEventId || "",
                    profile: {
                        profile_id: contextProfileId || "",
                    },
                },
                requirements: {
                    space: spaceIds
                        .filter(function (spaceId: number) {
                            return !!spaceIds;
                        })
                        .map(function (spaceId) {
                            return { space_id: spaceId };
                        }),
                    hide_conflicts: "F",
                    enable_override: "F",
                    allow_sharing: "F",
                },
                dates: datesArr.map(function (obj: any) {
                    return {
                        start_dt: S25Util.date.toS25ISODateTimeStr(obj.startDt),
                        end_dt: S25Util.date.toS25ISODateTimeStr(obj.endDt),
                    };
                }),
            },
        };

        return DataAccess.post(
            DataAccess.injectCaller("/space_avail.json", "SpaceService.getSpaceDatesAvailability"),
            body,
        );
    }

    public static getSpaceDatesAvailability(
        spaceIds: number[],
        datesArr: any,
        contextEventId?: number,
        contextProfileId?: number,
    ): Promise<{ spaces: WSSpacesAvail }> {
        return SpaceService.getSpaceDatesAvailabilityDao(spaceIds, datesArr, contextEventId, contextProfileId)
            .then(function (data) {
                return S25Util.prettifyJson(data, null, {
                    space: true,
                    dates: true,
                    conflict: true,
                    also_assign: true,
                    also_dates: true,
                    also_conflict: true,
                    subdivision_of: true,
                    subdivision_dates: true,
                    subdivision_conflict: true,
                });
            })
            .then(function (spaceAvail) {
                //// NOTE: comment out adjustment of closed "hours" conflicts due to ANG-3037 (hours conflicts are treated same as rsrv ones now)
                // jSith.forEach(spaceAvail.spaces && spaceAvail.spaces.space, function(_, space){
                //     jSith.forEach(["also", "subdivision", "direct"], function(_, relation){
                //         var spaces = (relation === "direct" ? S25Util.array.forceArray(space) :
                //             space[(relation === "also" ? "also_assign" : "subdivision_of")]);
                //         var prefix = (relation === "direct" ? "" : (relation + "_"));
                //         jSith.forEach(spaces, function(_, candidate){
                //             jSith.forEach(candidate[prefix + "dates"], function(_, spaceDate){
                //                 spaceDate[prefix + "conflict"] = spaceDate[prefix + "conflict"] || [];
                //                 var len = spaceDate[prefix + "conflict"] && spaceDate[prefix + "conflict"].length || 0;
                //                 var adjustedConflicts = [];
                //                 for(var i = len - 1; i >= 0; i--) {
                //                     var conflict = spaceDate[prefix + "conflict"][i];
                //
                //                     //hours conflict_start/end are actually open/closed hours
                //                     //so we must convert them to conflicts
                //                     //NOTE: only 1 closed hours can occur per day, guaranteed, so this is safe to do
                //                     if(conflict[prefix + "conflict_type"] === "hours") {
                //                         var open = conflict[prefix + "conflict_start"];
                //                         var closed = conflict[prefix + "conflict_end"];
                //
                //                         var clone1 = S25Util.deepCopy(conflict)
                //                         var clone2 = S25Util.deepCopy(conflict)
                //
                //                         spaceDate[prefix + "conflict"].splice(i, 1);
                //
                //                         if(open !== "00:00:00") {
                //                             clone1[prefix + "conflict_start"] = "00:00:00";
                //                             clone1[prefix + "conflict_end"] = open;
                //                             adjustedConflicts.push(clone1);
                //                         }
                //
                //                         if(closed !== "23:59:59") {
                //                             clone2[prefix + "conflict_start"] = closed;
                //                             clone2[prefix + "conflict_end"] = "23:59:59";
                //                             adjustedConflicts.push(clone2);
                //                         }
                //                     }
                //                 }
                //
                //                 spaceDate[prefix + "conflict"] = [].concat(spaceDate[prefix + "conflict"], adjustedConflicts);
                //             });
                //         });
                //     });
                // });
                return spaceAvail;
            });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SpaceService" })
    public static getSpaceReports() {
        //only 1 space report and it is hard-coded in BB
        //?space_id is available as a param but it does nothing for now
        //only rpt -147 has ever been used here and used to be hard-coded in BB...
        //edit p_space_reports to add more reports if needed
        return DataAccess.get(DataAccess.injectCaller("/spacereports.json", "SpaceService.getSpaceReports")).then(
            function (data) {
                return data && data.space_reports && data.space_reports.report;
            },
        );
    }

    //Get in a seprate method for easier mocking
    public static getSpacesIncludesDao(url: string, data: any) {
        return DataAccess.post(url, data);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SpaceService" })
    public static getSpacesIncludes(idArray: any, includes: string[]): Promise<any[]> {
        idArray = S25Util.array.forceArray(idArray);

        var url = DataAccess.injectCaller("/spaces.json?request_method=get", "SpaceService.getSpacesIncludes");
        var mapXml = S25Util.uglifyJson({
            mapxml: {
                space_id: idArray.join("+"),
                scope: "extended",
                include: includes.join("+"),
            },
        });
        return SpaceService.getSpacesIncludesDao(url, mapXml).then(function (data) {
            data = data && S25Util.prettifyJson(data, null, SPACE_ARRAYS);
            return S25Util.array.forceArray(data?.spaces?.space);
        });
    }

    public static getSpaceIncludes(idArray: any, includes: any) {
        return SpaceService.getSpacesIncludes(idArray, includes).then(function (spaces) {
            return spaces && spaces.length && spaces[0];
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SpaceService" })
    public static getSpaceSatisfaction(id: number): Promise<SpaceSatisfactionI[]> {
        return Eval25Service.displayLocationScore().then((showScore) => {
            if (!showScore) return null;
            return SpaceService.getSpacesIncludes([id], ["ratings"]).then((resp) => {
                let rating = Eval25Service.jsonToObj(resp[0]?.room_rating);
                return Eval25Service.sortScores(rating);
            });
        });
    }

    public static getHours(itemId: number) {
        let defaultOpen = "00:00:00";
        let defaultClose = "23:59:00";
        let defaultHours: any = [
            { day_id: 1, day_name: "Monday", open: defaultOpen, close: defaultClose },
            { day_id: 2, day_name: "Tuesday", open: defaultOpen, close: defaultClose },
            { day_id: 3, day_name: "Wednesday", open: defaultOpen, close: defaultClose },
            { day_id: 4, day_name: "Thursday", open: defaultOpen, close: defaultClose },
            { day_id: 5, day_name: "Friday", open: defaultOpen, close: defaultClose },
            { day_id: 6, day_name: "Saturday", open: defaultOpen, close: defaultClose },
            { day_id: 7, day_name: "Sunday", open: defaultOpen, close: defaultClose },
        ];
        return SpaceService.getSpaceIncludes([itemId], ["hours"]).then(
            (space) => {
                return (space && space.hours) || S25Util.deepCopy(defaultHours);
            },
            (err) => {
                return S25Util.deepCopy(defaultHours);
            },
        );
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SpaceService" })
    public static getSpacesMinimal(idArray: number[]) {
        return DataAccess.post<{ spaces: { space: S25WsSpace[] } }>(
            DataAccess.injectCaller("/spaces.json?request_method=get", "SpaceService.getSpacesMinimal"),
            S25Util.uglifyJson({ mapxml: { space_id: idArray.join("+"), scope: "minimal" } }),
        ).then(function (data) {
            data = data && S25Util.prettifyJson(data, null, SPACE_ARRAYS);
            return (data && data.spaces && data.spaces.space) || [];
        });
    }

    @Timeout
    public static getSpacesBySearchQuery(
        searchQuery: string,
        includes?: string[],
        dataScope?: string,
    ): Promise<{ spaces: Proto.ServiceMeta & { space: S25WsSpace[] } }> {
        if (includes && includes.length) {
            dataScope = "extended";
        }
        let url = "/spaces.json";
        url += dataScope ? (url.indexOf("?") > -1 ? "&" : "?") + "scope=" + dataScope : "";
        url += includes && includes.length ? (url.indexOf("?") > -1 ? "&" : "?") + "include=" + includes.join("+") : "";
        url += url.indexOf("?") > -1 ? searchQuery : searchQuery.replace("&", "?"); //note: only first & is replaced with ?
        return DataAccess.get(DataAccess.injectCaller(url, "SpaceService.getSpacesBySearchQuery")).then(
            function (data) {
                return (
                    data &&
                    S25Util.prettifyJson(data, null, {
                        space: true,
                        item: true,
                        layout: true,
                        categories: true,
                        contact: true,
                    })
                );
            },
        );
    }

    @Timeout
    public static getSpaceName(id: number) {
        return SpaceService.getSpaceNameAndFormal(id).then(function (data) {
            return data.itemName;
        });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SpaceService" })
    public static getSpaceFormal(id: number) {
        return SpaceService.getSpaceNameAndFormal(id).then(function (data: any) {
            return data.itemFormal;
        });
    }

    @Timeout
    public static async getSpaceNameAndFormal(id: number) {
        const data = await SpaceService.getSpaceMinimal(id);
        return { itemName: data?.space_name, itemFormal: data?.formal_name };
    }

    public static async getSpacesNameAndFormal(ids: number[]): Promise<S25ItemI[]> {
        if (!ids.length) return [];
        ids = S25Util.array.unique(ids);
        const objs: S25WsSpace[] = await SpaceService.getSpacesMinimal(ids);
        const objIdMap = S25Util.fromEntries(objs.map((obj) => [obj.space_id, obj]));
        const idIndexMap = S25Util.fromEntries(ids.map((id, index) => [id, index]));
        return (objs ?? [])
            .map((obj) => ({ itemId: obj.space_id, itemName: obj.space_name, itemDesc: obj.formal_name }))
            .concat(
                ids
                    .filter((id) => !objIdMap[id])
                    .map((id) => ({ itemId: id, itemName: S25Const.private, itemDesc: "" })),
            )
            .sort((a, b) => {
                return idIndexMap[S25Util.toInt(a.itemId)] - idIndexMap[S25Util.toInt(b.itemId)];
            });
    }

    @Timeout
    @Cache({ immutable: true, targetName: "SpaceService" })
    public static async getSpaceMinimal(id: number) {
        const data = await DataAccess.get<{
            spaces: Proto.ServiceMeta & { space: S25WsSpace };
        }>(DataAccess.injectCaller(`/space.json?space_id=${id}&scope=minimal`, "SpaceService.getSpaceMinimal"));
        return data?.spaces?.space;
    }

    @Timeout
    public static getSpaceAssignPerms(queryString: string) {
        const query = queryString
            .replace(/&spaces_/g, "&") // E.G. "spaces_query_id" -> "query_id"
            .replace(/&space_(name|query_id)/g, "&name");

        return UserprefService.getLoggedIn().then((isLoggedIn) => {
            if (!isLoggedIn) return {};
            return DataAccess.get(
                DataAccess.injectCaller(
                    "/micro/space/access/currentUser/list.json?include=assign_policy" + query,
                    "getSpaceAssignPerms",
                ),
            ).then(function (data) {
                return SpaceService.formPermsItems(data) || {};
            });
        });
    }

    @Timeout
    public static _getSpaceAssignPermsPage(url: string) {
        return DataAccess.get<SpaceAssignPermsPageResponse>(DataAccess.injectCaller(url, "getSpaceAssignPermsPage"));
    }

    @Timeout
    @Cache({ targetName: "SpaceService" })
    public static async getSpaceAssignPermsPage(
        queryString: string,
        paginateKey?: number,
        page: number = 1,
    ): Promise<Partial<SpaceAssignPermPage>> {
        const isLoggedIn = await UserprefService.getLoggedIn();
        if (!isLoggedIn) return {};

        const query = queryString
            .replace(/&spaces_/g, "&") // E.G. "spaces_query_id" -> "query_id"
            .replace(/&space_(name|query_id|favorite)/g, "&$1");
        const data = await SpaceService._getSpaceAssignPermsPage(
            `/micro/space/access/currentUser/list.json?include=assign_policy&paginate=${
                paginateKey || ""
            }&page=${page}&${query}`,
        );
        const response = {
            paginateKey: data.content?.data?.paginateKey,
            page: data.content?.data?.pageIndex,
            totalPages: data.content?.data?.totalPages,
            totalItems: data.content?.data?.totalItems,
            currentItemCount: data.content?.data?.currentItemCount,
            itemsPerPage: data.content?.data?.itemsPerPage,
        } as SpaceAssignPermPage;
        response.items = SpaceService.formPermsItems(data) || new Map();
        return response;
    }

    public static formPermsItems(data: SpaceAssignPermsPageResponse) {
        if (data.content?.data?.items?.length) {
            return new Map(
                data.content.data.items.map((p) => {
                    let assignPolicy = p?.assignPolicy?.length && p.assignPolicy[0];
                    if (assignPolicy && assignPolicy?.assignPerm?.length === 1) {
                        let mapping: any = {
                            N: "noRequest",
                            R: "request",
                            C: "assign",
                            F: "assignApprove",
                            null: "notSet",
                            undefined: "notSet",
                            "": "notSet",
                        };
                        assignPolicy.assignPerm = mapping[assignPolicy.assignPerm];
                    }
                    return [p.id, assignPolicy];
                }),
            );
        } else {
            return null;
        }
    }

    @Timeout
    public static async getExpressSpaces(start: Date, end: Date): Promise<S25WsSpace[]> {
        const permStart = `perm_start_dt=${S25Util.date.toS25ISODateTimeStr(start)}`;
        const permEnd = `perm_end_dt=${S25Util.date.toS25ISODateTimeStr(end)}`;
        let query = `&direct=T&min_ols=R&can_assign=T&${permStart}&${permEnd}`;
        return SpaceService.getSpacesBySearchQuery(query, ["direct_schedule"]).then(
            function (spaces) {
                return spaces?.spaces?.space;
            },
            function () {
                return [];
            },
        );
    }

    @Timeout
    public static isExpress(id: number, start: Date, end: Date) {
        const permStart = `perm_start_dt=${S25Util.date.toS25ISODateTimeStr(start)}`;
        const permEnd = `perm_end_dt=${S25Util.date.toS25ISODateTimeStr(end)}`;
        let query = `&space_id=${id}&direct=T&min_ols=R&can_assign=T&${permStart}&${permEnd}`;
        return SpaceService.getSpacesBySearchQuery(query).then(
            function (spaces) {
                return spaces?.spaces?.space?.length > 0;
            },
            function () {
                return false;
            },
        );
    }

    @Timeout
    public static isExpressAvail(id: number, startDt: Date, endDt: Date) {
        return SpaceService.isExpress(id, startDt, endDt).then(
            function (isExpress) {
                return (
                    isExpress &&
                    SpaceService.getSpaceDatesAvailability([id], [{ startDt: startDt, endDt: endDt }]).then(
                        function (spaceAvail) {
                            return (
                                spaceAvail &&
                                spaceAvail.spaces &&
                                spaceAvail.spaces.space &&
                                spaceAvail.spaces.space.length &&
                                spaceAvail.spaces.space[0].has_conflicts !== "T"
                            );
                        },
                        function () {
                            return false;
                        },
                    )
                );
            },
            function () {
                return false;
            },
        );
    }

    @Timeout
    public static getSpaceAlwaysShare(id: number) {
        return SpaceService.getSpacesMinimal([id]).then(function (data) {
            return data && data.length && data[0].always_shared === "T";
        });
    }

    @Timeout
    public static setSpaceAlwaysShare(id: number, alwaysShare: boolean) {
        return DataAccess.put(DataAccess.injectCaller("/space/share.json", "SpaceService.setSpaceAlwaysShare"), {
            root: {
                room_id: id,
                is_shared: alwaysShare ? 1 : 0,
            },
        });
    }

    @Timeout
    public static async createLocation(
        name: string,
        maxCapacity: number,
        formalName?: string,
        content?: Location.MicroItem,
    ): Promise<Location.MicroItem> {
        let locationItem: Location.MicroItem = S25Util.extend(
            {
                spaceName: name,
                spaceFormalName: formalName || "",
                maxCapacity: maxCapacity,
            },
            content,
        );

        const [resp, error] = await S25Util.Maybe(
            DataAccess.post(
                DataAccess.injectCaller("/micro/space/new.json", "SpaceService.setLocation"),
                MicroUtil.wrapPayloadItem(locationItem),
            ),
        );
        if (error) {
            return Promise.reject(error?.error);
        }

        return MicroUtil.getMicroItem(resp) as Location.MicroItem;
    }

    @Timeout
    public static getAllSpaceChanges() {
        //rm_changes service for optimizer effective dating
        return DataAccess.get(DataAccess.injectCaller("/rm_changes.json", "SpaceService.getAllSpaceChanges")).then(
            function (data) {
                var changes = S25Util.prettifyJson(data, null, { rm_change: true });
                return S25Util.propertyGet(changes, "rm_change");
            },
        );
    }

    @Invalidate({ serviceName: "SpaceService" })
    public static getSpaceChangesWithNames(params?: any) {
        return SpaceService.getAllSpaceChanges().then(function (spaceChanges) {
            var spaceIds =
                spaceChanges &&
                S25Util.array.unique(
                    spaceChanges.map(function (obj: any) {
                        return obj.room_id;
                    }) as number[],
                );

            var spaceNameLookup: any = {},
                featureNameLookup: any = {},
                partitionNameLookup: any = {};
            return S25Util.all({
                spaces: spaceIds && SpaceService.getSpacesMinimal(spaceIds),
                features: spaceIds && SearchCriteriaService.getSpaceFeatures(),
                partitions: spaceIds && SearchCriteriaService.getLocationPartitions(),
            }).then(function (resp) {
                resp.spaces &&
                    jSith.forEach(resp.spaces, function (_, space) {
                        spaceNameLookup[space.space_id] = space.space_name;
                    });

                resp.features &&
                    jSith.forEach(resp.features, function (_, feature) {
                        featureNameLookup[feature.itemId] = feature.itemName;
                    });

                resp.partitions &&
                    jSith.forEach(resp.partitions, function (_, partition) {
                        partitionNameLookup[partition.itemId] = partition.itemName;
                    });

                spaceChanges &&
                    jSith.forEach(spaceChanges, function (_, spaceChange) {
                        spaceChange.itemName = spaceNameLookup[spaceChange.room_id];
                        spaceChange.itemId = spaceChange.room_id;
                        spaceChange.change_type = parseInt(spaceChange.change_type);
                        spaceChange.change_value = parseInt(spaceChange.change_value);
                        if (spaceChange.change_type === 3 && spaceChange.change_value > 100) {
                            spaceChange.change_value = 100;
                        }
                        //// ************* comment out this ANG-ANG-3600 ************/////
                        // if(spaceChange.change_type===5 && spaceChange.change_value> 999) {
                        // 	spaceChange.change_value = 999;
                        // }
                        spaceChange.changeTypeText = S25Const.spaceChangeType2Text[spaceChange.change_type];
                        switch (spaceChange.change_type) {
                            case 1:
                                spaceChange.changeValueText = featureNameLookup[spaceChange.change_value];
                                break;
                            case 2:
                                spaceChange.changeValueText = featureNameLookup[spaceChange.change_value];
                                break;
                            case 3:
                                spaceChange.changeValueText = spaceChange.change_value;
                                break;
                            case 4:
                                spaceChange.changeValueText = partitionNameLookup[spaceChange.change_value];
                                break;
                            case 5:
                                spaceChange.changeValueText = spaceChange.change_value;
                                break;
                        }
                    });
                return spaceChanges && spaceChanges.sort(S25Util.shallowSort("itemName"));
            });
        });
    }

    public static getSpaceChangesList() {
        var colsArray = [
            { name: "Location Name", sortable: 1, prefname: "location_name", isDefaultVisible: 1 },
            { name: "Effective Date", sortable: 1, prefname: "effective_date", isDefaultVisible: 1 },
            { name: "Change Type", sortable: 1, prefname: "change_type", isDefaultVisible: 1 },
            { name: "Change", sortable: 1, prefname: "change", isDefaultVisible: 1 },
        ];
        var changeOptions = S25Util.array.propertyListToArray(S25Const.spaceChangeType2Text);
        var rowsF = function (listData: any) {
            var ret: any[] = [];
            if (listData) {
                jSith.forEach(listData, function (_, listRow) {
                    //set data rows
                    //date cell provides its dataChange function, which is called by listDatepicker on change
                    var dateCell: any = {
                        templateType: 20,
                        date: listRow.effective_date,
                        previousDate: S25Util.date.parse(listRow.effective_date), //initial previous value
                        dateChange: function () {
                            var changeCell = dateCell.getCell(dateCell.row, "change"); //get change cell
                            changeCell.changeDateChange(dateCell.datepickerBean.date, dateCell.previousDate); //change date (calls ajax too)
                            dateCell.previousDate = dateCell.datepickerBean.date; //maintain own previous value
                        },
                    };
                    var row = {
                        contextId:
                            listRow.itemId +
                            " " +
                            listRow.change_type +
                            " " +
                            listRow.change_value +
                            " " +
                            listRow.effective_date, //required for list-check to register which item was checked
                        row: [
                            listRow.itemName,
                            dateCell, //date cell
                            {
                                templateType: 10,
                                changeType: listRow.change_type,
                                options: changeOptions,
                                itemName: listRow.changeTypeText,
                            },
                            {
                                templateType: 11,
                                fillRatio: (listRow.change_type === 3 && listRow.changeValueText) || 0,
                                maxCapacity: (listRow.change_type === 5 && listRow.changeValueText) || 0,
                                itemName: listRow.changeValueText,
                                changeValueText: listRow.changeValueText,
                                changeValue: listRow.change_value,
                                changeType: listRow.change_type,
                                origChangeType: listRow.change_type,
                            },
                        ],
                    };
                    ret.push(row);
                });
            }
            return ret;
        };
        return ListGeneratorService.s25Generate(null, colsArray, rowsF, SpaceService.getSpaceChangesWithNames);
    }

    public static deleteSpaceChangeModels(models: any) {
        return SpaceService.setSpaceChanges(S25Util.array.forceArray(models), "del");
    }

    public static insertSpaceChangeModels(models: any) {
        return SpaceService.setSpaceChanges(S25Util.array.forceArray(models), "new");
    }

    public static updateLayout(
        layoutId: number,
        newLayoutName: string,
        locationIds: number[],
        multiplier: number,
        isDefault: boolean,
        typeId: string,
        imageId?: number,
        diagramId?: number,
    ) {
        var url =
            "/space/layout/capacity.json?default=" + (isDefault ? 1 : 0) + "&val=" + multiplier + "&typeId=" + typeId;

        if (layoutId) {
            url += "&conf_id=" + layoutId;
        } else if (newLayoutName) {
            url += "&itemName=" + encodeURIComponent(newLayoutName);
        }

        imageId || imageId === null ? (url += "&photo_id=" + imageId + "&isPhotoEdit=0") : (url += "&isPhotoEdit=1");
        diagramId || diagramId === null
            ? (url += "&diagram_id=" + diagramId + "&isDiagramEdit=0")
            : (url += "&isDiagramEdit=1");

        return DataAccess.put(DataAccess.injectCaller(url, "SpaceService.updateLayout"), {
            root: {
                locations: locationIds.map(function (id) {
                    return { room_id: id };
                }),
            },
        });
    }

    public static updateMaxCapacity(locationIds: number[], multiplier: number, typeId: string) {
        return DataAccess.put(
            DataAccess.injectCaller(
                "/space/capacity.json?val=" + multiplier + "&typeId=" + typeId,
                "SpaceService.updateMaxCapacity",
            ),
            {
                root: {
                    locations: locationIds.map(function (id) {
                        return { room_id: id };
                    }),
                },
            },
        );
    }

    @Timeout
    private static setSpaceChanges(models: any, status: string) {
        let payload = { rm_changes: { rm_change: new Array(models.length) } };
        for (let i = 0; i < models.length; i++) {
            payload.rm_changes.rm_change[i] = {
                status: status || "mod",
                room_id: models[i].spaceId,
                change_type: models[i].changeType,
                change_value: models[i].changeValue || 0,
                effective_date: S25Util.date.toS25ISODateStr(models[i].effectiveDate),
            };
        }
        return DataAccess.put(
            DataAccess.injectCaller("/rm_changes.json", "SpaceService.setSpaceChanges"),
            payload,
        ).then(
            function (resp) {
                return S25Util.prettifyJson(resp);
            },
            function (error) {
                return { error: error.error };
            },
        );
    }

    public static updateFillRatio(locationIds: number[], multiplier: number) {
        return DataAccess.put(
            DataAccess.injectCaller("/space/fill/ratio.json?val=" + multiplier, "SpaceService.updateFillRatio"),
            {
                root: {
                    locations: locationIds.map(function (id) {
                        return { room_id: id };
                    }),
                },
            },
        );
    }

    // update comments / default set-up  instructions
    public static updateText(ids: number[], type: string, text: string) {
        return DataAccess.put(DataAccess.injectCaller("/space/text.json?", "SpaceService.updateText"), {
            root: {
                type: type,
                text_comment: text,
                locations: ids.map(function (id) {
                    return { room_id: id };
                }),
            },
        });
    }

    public static updateScheduler(ids: number[], contId: any) {
        return DataAccess.put(
            DataAccess.injectCaller("/space/role.json?role_id=-1&val=" + contId, "SpaceService.updateScheduler"),
            {
                root: {
                    locations: ids.map(function (id) {
                        return { room_id: id };
                    }),
                },
            },
        );
    }

    public static updatePartition(ids: number[], val: any) {
        return DataAccess.put(
            DataAccess.injectCaller("/space/partition.json?val=" + val, "SpaceService.updatePartition"),
            {
                root: {
                    locations: ids.map(function (id) {
                        return { room_id: id };
                    }),
                },
            },
        );
    }

    public static updateCategories(ids: number[], addIds: [], removeIds: []) {
        return DataAccess.put(DataAccess.injectCaller("/space/categories.json", "SpaceService.updateCategories"), {
            root: {
                cats_add: addIds.map(function (a: any) {
                    return { cat_id: a.itemId };
                }),
                cats_remove: removeIds.map(function (a: any) {
                    return { cat_id: a.itemId };
                }),
                locations: ids.map(function (id) {
                    return { room_id: id };
                }),
            },
        });
    }

    public static updateFeatures(ids: number[], addIds: [], removeIds: []) {
        return DataAccess.put(DataAccess.injectCaller("/space/features.json", "SpaceService.updateFeatures"), {
            root: {
                chars_add: addIds.map(function (a: any) {
                    return { char_id: a.itemId, char_count: a.quantity };
                }),
                chars_remove: removeIds.map(function (a: any) {
                    return { char_id: a.itemId };
                }),
                locations: ids.map(function (id) {
                    return { room_id: id };
                }),
            },
        });
    }

    public static updateExpress(ids: number[], eventTypeId: number, duration: number) {
        return DataAccess.put(DataAccess.injectCaller("/space/express.json", "SpaceService.updateExpress"), {
            root: {
                locations: ids.map(function (id) {
                    return { room_id: id, event_type_id: eventTypeId, max_duration_minutes: duration };
                }),
            },
        });
    }

    public static deleteExpress(ids: number[]) {
        return DataAccess.delete(DataAccess.injectCaller("/space/express.json", "SpaceService.updateExpress"), {
            root: {
                locations: ids.map(function (id) {
                    return { room_id: id };
                }),
            },
        });
    }

    public static async copySpace(itemId: number, newItem: S25ItemI, fields?: any, copyProps?: CopyPropertiesI) {
        const sourceSpace = await DataAccess.get(
            DataAccess.injectCaller(`/micro/space/${itemId}/detail.json?include=all`, "SpaceService.copySpaceMicro"),
        );
        let newSpaceData: Location.MicroItem = sourceSpace.content.data?.items[0];

        //Don't need any of these for space POST
        delete newSpaceData.id;
        delete newSpaceData.etag;

        newSpaceData.spaceName = newItem.itemName || "";
        newSpaceData.spaceFormalName = newItem.itemFormal || "";

        //GET and PUT use a different format for layouts
        const origLayouts = newSpaceData.layouts as Layout[];
        if (origLayouts?.length) {
            newSpaceData.layouts = {
                default: formatLayoutForPut(origLayouts.find((layout: Layout) => layout.defaultLayout)),
                additional: origLayouts.filter((layout: Layout) => !layout.defaultLayout).map(formatLayoutForPut),
            };
        }

        const securityOptions = CopyObjectUtil.convertSecurityOptions(itemId, copyProps);
        let newSpace = S25Util.extend(newSpaceData, securityOptions);

        const [respSpace, error] = await S25Util.Maybe(
            SpaceService.createLocation(newItem.itemName || "", null, newItem.itemFormal || "", newSpace),
        );
        if (error) {
            return Promise.reject(error);
        }

        return respSpace?.id;
    }

    @Timeout
    public static deleteLocations(ids: any) {
        let payload: any = { map: { space_id: ids } };

        return DataAccess.delete(DataAccess.injectCaller("/spaces.json", "SpaceService.deleteLocations"), payload).then(
            function (resp) {
                resp = S25Util.prettifyJson(resp);
                let isSuccess =
                    (resp && resp.results && resp.results.info && resp.results.info.msg_id) === "RM_I_DELETED";
                let noPerms =
                    resp &&
                    resp.results &&
                    resp.results.noPerm &&
                    resp.results.noPerm.item &&
                    S25Util.array.forceArray(resp.results.noPerm.item).map((item: any) => {
                        return {
                            itemName: item.object_name,
                            itemId: item.object_id,
                            itemTypeId: item.object_type,
                        };
                    });

                return { error: !isSuccess, success: isSuccess, noPerms: { items: noPerms } };
            },
            function (error) {
                S25Util.showError(error);
                return { error: error, success: false };
            },
        );
    }

    public static deleteLayouts(ids: number[], addIds: [], removeIds: []) {
        return DataAccess.delete(DataAccess.injectCaller("/space/layouts.json", "SpaceService.deleteLayouts"), {
            root: {
                layouts_remove: removeIds.map(function (a: any) {
                    return { conf_id: a.itemId };
                }),
                locations: ids.map(function (id) {
                    return { room_id: id };
                }),
            },
        });
    }

    public static updateHours(ids: number[], hoursUpdate: []) {
        return DataAccess.put(DataAccess.injectCaller("/space/hours.json", "SpaceService.updateHours"), {
            root: {
                hours_update: hoursUpdate.map(function (a: any) {
                    return { day_of_week: a.day_of_week, close_tm: a.close_tm, open_tm: a.open_tm };
                }),
                locations: ids.map(function (id) {
                    return { room_id: id };
                }),
            },
        });
    }

    @Invalidate({ serviceName: "SpaceService" })
    @Timeout
    public static microUpdate(payload: any) {
        return DataAccess.put(DataAccess.injectCaller("/micro/space/list.json", "SpaceService.microUpdate"), {
            content: {
                apiVersion: "0.1",
                data: [payload],
            },
        });
    }

    public static getSpacesByTypeId(ids: number[], type: string): Promise<{ [key: number]: Location.Service[] }> {
        ids = S25Util.array.forceArray(ids) as number[];
        return DataAccess.get(
            DataAccess.injectCaller("/spaces.json?" + type + "_id=" + ids.join("+"), "SpaceService.getSpacesByPartId"),
        ).then(function (data: any) {
            return data.spaces?.space || [];
        });
    }
}

function formatLayoutForPut(layout: Layout): Layout2 {
    return {
        layoutId: layout.layoutId,
        maxCapacity: layout.layoutCapacity,
        // sharedUse: 0,
        setupTm: layout.setupTm,
        takedownTm: layout.tdownTm,
        photoType: 1,
        photoId: layout.layoutPhotoId,
        diagramType: 1,
        diagramId: layout.layoutDiagramId,
        instructions: layout.layoutInstruction,
    };
}

export type Blackout = {
    can_edit_all: NumericalBoolean;
    init_end_dt: ISODateString;
    init_start_dt: ISODateString;
    profile_code: string;
    profile_name: string;
    rec_type: number;
    date: BlackoutDate[];
    room: BlackoutRoom[];
    comment: string;
};

export type BlackoutDate = {
    start_dt: ISODateString;
    end_dt: ISODateString;
};

export type BlackoutRoom = {
    can_edit: NumericalBoolean;
    room_id: number;
    room_short: string;
};

type SpaceAssignPermsPageResponse = {
    content: {
        requestId: number;
        updated: OffsetISODateString;
        data: SpaceAssignPermsPageResponseData;
    };
};

type SpaceAssignPermsPageResponseData = {
    currentItemCount: number;
    itemsPerPage: number;
    pageIndex: number;
    paginateKey: number;
    pagingLinkTemplate: string;
    totalItems: number;
    totalPages: number;
    items: {
        etag: string;
        id: number;
        kind: "space";
        assignPolicy: EventPerm[];
    }[];
};

export type SpaceAssignPermPage = {
    paginateKey: SpaceAssignPermsPageResponseData["paginateKey"];
    page: SpaceAssignPermsPageResponseData["pageIndex"];
    totalPages: SpaceAssignPermsPageResponseData["totalPages"];
    totalItems: SpaceAssignPermsPageResponseData["totalItems"];
    currentItemCount: SpaceAssignPermsPageResponseData["currentItemCount"];
    itemsPerPage: SpaceAssignPermsPageResponseData["itemsPerPage"];
    items: Map<number, EventPerm>;
};
